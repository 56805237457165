.react-calendar {
    width: 370px;
    min-height: 420px;
    border: none;
    margin-top: 40px;
}

.react-calendar * {
    font-family: 'Roboto';
}

.react-calendar__navigation__label {
    font-size: 15px;
    border-radius: 4px;
}

.react-calendar__navigation__arrow {
    font-size: 20px !important;
    border-radius: 4px;
}

.react-calendar__month-view__days,
.react-calendar__year-view__months,
.react-calendar__decade-view__years,
.react-calendar__century-view__decades {
    justify-content: space-between;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    flex: 0 0 32.3333% !important;
}

.react-calendar__month-view__days__day {
    flex: 0 0 13.2857% !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
    font-family: 'Roboto Condensed' !important;
}

.react-calendar__tile--now {
    color: #fff;
    background: #5f89b1;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    color: #fff;
    background: #5f89b1;
    opacity: 0.8;
}

.react-calendar__tile--active {
    color: #fff;
    background: #405e7b;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    color: #fff;
    background: #405e7b;
    opacity: 0.8;
}


.react-calendar__tile--hasActive {
    color: #fff;
    background: #405e7b;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    color: #fff;
    background: #405e7b;
    opacity: 0.8;
}

.react-calendar__tile {
    /* min-height: 44px; */
    padding: 15px 6.6667px;
    border-radius: 4px;
    margin-bottom: 5px !important;
}

.react-calendar__tile:disabled {
    /* border-radius: 0 !important; */
    /* flex: 0 0 14.2857% !important; */
}

.react-calendar__tile abbr {
    font-size: 14px;
}